            <template>
  <div ref="solution">
    <div v-for="(product, product_index) in product_detail" :key="_uid+'solutions'+product_index">

      <!-- SOLUZIONE NON DISPONIBILE -->
      <section v-if="product.soluzioni_parsed.length === 0" class="solution text-center">
        <div class="solution__not-found">
          <img src="/assets/images/alpitour/statics/symbol_w_girl.png" />
          <p>
            Siamo spiacenti! Non ci sono soluzioni disponibili.<br>
            <strong>Prova a selezionare un'altra proposta o a cambiare i criteri di ricerca.</strong>
          </p>
        </div>
        <router-link :to="{name: 'results'}">
          <span class="vb-detail__back">indietro</span>
        </router-link>
      </section>

      <!-- LISTA DELLE SOLUZIONI -->
      <section v-else class="solution mb-4">
        <h2 class="solution__title vb-heading">Le soluzioni disponibili</h2>

        <vb-tabs class="solution__tabs"
            :vb_options="{
              nav_class: 'solution__tabs-nav',
              content_class: 'solution__tabs-content',
            }"
        >
          <b-tab v-for="(solution_parsed, solution_parsed_index) in product.soluzioni_parsed"
                 v-bind:key="_uid+solution_parsed_index"
                 :title="solution_parsed.label | capitalize"
                 class="solution__tabs-content_panel"
          >
            <div class="solution__content" v-for="(solution, solution_index) in solution_parsed.soluzioni"
                 v-bind:key="_uid+solution_index" :set_parsed_flights="parsed_flights = parse_flights(solution.flights)"
            >
              <div class="solution__recap">
                <span class="solution__recap-title" v-html="'Soluzione'+ (solution_index + 1)" />

                <div class="solution__recap-wrap">
                  <!-- Date -->
                  <div class="solution__recap-element">
                    <vb-icon class="solution__recap-icon" :name="'calendar'" :size=16 :color="sass('primary')" />
                    <span class="solution__recap-departure">
                      {{parse_date(parsed_flights.andata.from.date).day_number}}
                      {{parse_date(parsed_flights.andata.from.date).month_string}}
                      {{parse_date(parsed_flights.andata.from.date).year}}
                    </span>
                    <span class="solution__recap-arrival">
                      {{parse_date(parsed_flights.ritorno.from.date).day_number}}
                      {{parse_date(parsed_flights.ritorno.from.date).month_string}}
                      {{parse_date(parsed_flights.ritorno.from.date).year}}
                    </span>
                    <span class="solution__recap-nights">{{parse_trattamento(solution.hotels).notti}} notti</span>
                  </div>
                  <!-- Aeroporti -->
                  <div class="solution__recap-element">
                    <vb-icon class="solution__recap-icon" :name="'airplane'" :size=16 :color="sass('primary')" />
                    <span>
                      {{parsed_flights.andata.from.label | capitalize}} <span class="solution__recap-iata">({{parsed_flights.andata.from.iata}})</span>
                      - {{parsed_flights.ritorno.from.label | capitalize}} <span class="solution__recap-iata">({{parsed_flights.ritorno.from.iata}})</span>
                    </span>
                  </div>
                  <!-- A partire da -->
                  <div class="solution__recap-element">
                    <span>da {{markup(parse_trattamento(solution.hotels).a_partire_da).display_price}}€</span>
                  </div>
                </div>
              </div>

              <div class="solution__segment">
                <!-- Andata -->
                <span v-if="is_mobile()" class="solution__segment-title" v-html="'Andata'" />
                <div class="solution__segment-element solution__segment-element--departure">
                  <vb-icon class="solution__segment-icon solution__segment-icon--departure" :name="'air_start'" :size=16 :color="sass('primary')" />
                  <span class="solution__segment-label">
                    {{parse_date(parsed_flights.andata.from.date).day_number}}
                    {{parse_date(parsed_flights.andata.from.date).month_string}}
                    {{parse_date(parsed_flights.andata.from.date).year}}
                  </span>
                  <span class="solution__segment-time">
                    {{parsed_flights.andata.from.time.slice(0,5)}} -
                    {{parsed_flights.andata.to.time.slice(0,5)}}
                  </span>
                  <span class="solution__segment-plus"
                        v-if="parsed_flights.andata.signaln > 0"
                        v-html="'(+' + parsed_flights.andata.signaln + ')'"
                  />
                  <div class="solution__segment-type">
                    <span v-if="parsed_flights.andata.scali !== 0"
                          v-html="'scali: ' + parsed_flights.andata.scali"
                          v-b-modal="'modal-segments-'+solution.flights[0].aptPart+(solution_index + 1)"
                          class="text-info"
                    />
                    <span v-else v-html="'diretto'" />
                  </div>
                </div>
                <!-- Ritorno -->
                <span v-if="is_mobile()" class="solution__segment-title" v-html="'Ritorno'" />
                <div class="solution__segment-element solution__segment-element--return">
                  <vb-icon class="solution__segment-icon solution__segment-icon--return" :name="'air_start'" :size=16 :color="sass('primary')" />
                  <span class="solution__segment-label">
                    {{parse_date(parsed_flights.ritorno.from.date).day_number}}
                    {{parse_date(parsed_flights.ritorno.from.date).month_string}}
                    {{parse_date(parsed_flights.ritorno.from.date).year}}
                  </span>
                  <span class="solution__segment-time">
                    {{parsed_flights.ritorno.from.time.slice(0,5)}} -
                    {{parsed_flights.ritorno.to.time.slice(0,5)}}
                  </span>
                  <span class="solution__segment-plus"
                        v-if="parsed_flights.ritorno.signaln > 0"
                        v-html="'(+' + parsed_flights.ritorno.signaln + ')'"
                  />
                  <div class="solution__segment-type">
                    <span v-if="parsed_flights.ritorno.scali !== 0"
                          v-html="'scali: ' + parsed_flights.ritorno.scali"
                          v-b-modal="'modal-segments-'+solution.flights[0].aptPart+(solution_index + 1)"
                          class="text-info"
                    />
                    <span v-else v-html="'diretto'" />
                  </div>
                </div>

                <vb-modal
                    :vb_options="{
                    id: 'modal-segments-' + solution.flights[0].aptPart+(solution_index + 1),
                    body_class: 'solution__modal solution__modal--segment',
                    size: 'xl',
                    centered: true,
                    hide_footer: true
                  }"
                >
                  <!-- Andata -->
                  <div class="solution__modal-element">
                    <div
                        class="solution__modal-title"
                    >
                      <vb-icon class="solution__modal-title_icon" :name="'air_start'" :size=16 :color="sass('vb-body-color')" />
                      {{'Andata: ' + parse_date(parsed_flights.andata.from.date).day_number + ' ' + parse_date(parsed_flights.andata.from.date).month_string + ' ' + parse_date(parsed_flights.andata.from.date).year }}
                    </div>
                    <div class="solution__modal-wrap">
                      <div class="segment" v-for="(segment, segment_index) in parsed_flights.andata.segments"
                           :key="_uid+segment_index">
                        <div class="segment__element">
                          <div class="segment__time">{{segment.orarioPart.slice(0,5)}} - {{segment.orarioArr.slice(0,5)}}</div>
                          <span class="segment__plus"
                                v-if="segment.signaln"
                                v-html="'(+' + segment.signaln +')'"
                          />
                          <div class="segment__flight">
                            {{segment.descrAptPart | capitalize}} ({{segment.aptPart}}) - {{segment.descrAptArr | capitalize}} ({{segment.aptPart}})
                          </div>
                          <img class="segment__logo" :src="'https://static.vadobay.com/airlines-logos/100x50/'+segment.siglaVolo+'.png'" />
                        </div>
                        <span class="segment__deco"></span>
                      </div>
                    </div>
                  </div>

                  <!-- Ritorno -->
                  <div class="solution__modal-element">
                    <div class="solution__modal-title">
                      <vb-icon class="solution__modal-title_icon" :name="'air_start'" :size=16 :color="sass('vb-body-color')" />
                      {{'Ritorno: ' + parse_date(parsed_flights.ritorno.from.date).day_number + ' ' + parse_date(parsed_flights.ritorno.from.date).month_string + ' ' + parse_date(parsed_flights.ritorno.from.date).year}}
                    </div>
                    <div class="solution__modal-wrap">
                      <div class="segment" v-for="(segment, segment_index) in parsed_flights.ritorno.segments"
                           :key="_uid+segment_index">
                        <div class="segment__element">
                          <div class="segment__time">{{segment.orarioPart.slice(0,5)}} - {{segment.orarioArr.slice(0,5)}}</div>
                          <span class="segment__plus"
                                v-if="segment.signaln"
                                v-html="'(+' + segment.signaln +')'"
                          />
                          <div class="segment__flight">
                            {{segment.descrAptPart | capitalize}} ({{segment.aptPart}}) - {{segment.descrAptArr | capitalize}} ({{segment.aptPart}})
                          </div>
                          <img class="segment__logo" :src="'https://static.vadobay.com/airlines-logos/100x50/'+segment.siglaVolo+'.png'" />
                        </div>
                        <span class="segment__deco"></span>
                      </div>
                    </div>
                  </div>

                  <span class="solution__modal-info">
                    <disclaimer-flights-info />
                  </span>

                </vb-modal>

                <!-- Vettori -->
                <div class="solution__segment-element solution__segment-element--carriers">
                  <img class="solution__segment-logo"
                       :src="'https://static.vadobay.com/airlines-logos/100x50/'+parsed_flights.andata.from.company+'.png'"
                  >
                  <span class="solution__segment-label">
                    {{get_company(parsed_flights.andata.from.company).name | capitalize}}
                    ({{parsed_flights.andata.from.company}})
                  </span>
                </div>
                <!-- Tipo Volo -->
                <div class="solution__segment-element solution__segment-element--typeflight">
                  <vb-icon class="solution__segment-icon" :name="'airplane'" :size=16 :color="sass('primary')" />
                  <span class="solution__segment-label">
                    {{parsed_flights.andata.from.tipoVolo}}
                  </span>
                </div>
                <!-- Classe -->
                <div class="solution__segment-element solution__segment-element--class">
                  <vb-icon class="solution__segment-icon" :name="'ticket'" :size=16 :color="sass('primary')" />
                  <div class="solution__segment-label">
                    <span>{{get_classes(parsed_flights.andata.from.classeVolo)}}</span>
                    <span v-if="get_classes(parsed_flights.andata.from.classeVolo) !== parsed_flights.andata.from.classeVolo">
                      ({{parsed_flights.andata.from.classeVolo}})
                    </span>
                  </div>
                  <vb-icon v-if="get_classes(parsed_flights.andata.from.classeVolo) === parsed_flights.andata.from.classeVolo" class="solution__segment-icon solution__segment-icon--info" :name="'info'" :color="sass('gray-500')" :size="13"
                           v-b-popover.hover.top="'Per conoscere la classe ti preghiamo di contattare l\'assistenza in webchat o via email .'"
                  />
                </div>
                <!-- Baggage -->
                <div class="solution__segment-element solution__segment-element--baggage" v-b-modal="'modal-baggage-'+solution.flights[0].aptPart+(solution_index + 1)">
                  <vb-icon class="solution__segment-icon" :name="'baggage'" :size=16 :color="sass('primary')" />
                  <span class="solution__segment-label" v-html="'1 + 1'" />
                  <vb-icon class="solution__segment-icon solution__segment-icon--info" :name="'info'" :color="sass('gray-500')" :size="13" />

                  <vb-modal
                      :vb_options="{
                        id: 'modal-baggage-'+solution.flights[0].aptPart+(solution_index + 1),
                        title: 'I bagagli sul tuo volo',
                        centered: true,
                        hide_footer: true
                      }"
                  >
                    <strong>Tutti i pacchetti</strong> includono per ogni passeggero adulto e per ogni passeggero bambino, un bagaglio a mano in cabina ed uno in stiva.
                    <br><br>
                    <strong>I voli charter Neos</strong> (indicati nell'operativo in pagina come NO) includono per ciascun passeggero adulto e per ciascun passeggero bambino un bagaglio a mano in cabina pari a 8 kg e un bagaglio in stiva del peso massimo di 15 kg o di 23 kg nel caso di volo con durata superiore alle 6 ore (vedi dettagli in pagina).
                    <br><br>
                    Se il volo Neos è superiore alle 6 ore ed è di classe D (Premium), la franchigia del bagaglio in stiva sale a 30 kg.
                    <br><br>
                    Per conoscere la franchigia bagaglio dei <strong>voli di compagnie diverse da Neos</strong> ti preghiamo di contattare l'assistenza in webchat o via email .
                  </vb-modal>
                </div>

                <!-- Button Dettagli e Info Volo mobile -->
                <div v-if="is_mobile()" class="solution__segment-element solution__segment-element--cta">
                  <span class="solution__segment-link solution__segment-link--info"
                        v-b-modal="'modal-segments-mobile-' + solution.flights[0].aptPart+(solution_index + 1)"
                        v-html="'Info Volo'"/>
                  <div class="solution__segment-link solution__segment-link--detail" v-b-toggle="_uid+solution.flights[0].aptPart+solution_index">
                    <span v-html="'Dettagli'"/>
                    <vb-icon class="solution__segment-icon solution__segment-icon--arrow" :name="'angle_small_down'" :size=20 :color="sass('info')" />
                  </div>

                </div>

                <div v-else class="solution__segment-element" v-b-toggle="_uid+solution.flights[0].aptPart+solution_index">
                  <vb-button class="solution__segment-btn solution__segment-btn--details  shadow" :vb_options="{text: 'Dettagli', variant: 'info', size: 'sm'}" />
                </div>

                <!-- Modale Info Volo mobile -->
                <vb-modal
                    :vb_options="{
                        id: 'modal-segments-mobile-' + solution.flights[0].aptPart+(solution_index + 1),
                        body_class: 'solution__modal solution__modal--volo',
                        title: 'Informazioni sul volo',
                        size: 'xl',
                        centered: true,
                        hide_footer: true
                      }"
                >
                  <ul class="solution__modal-ul">
                    <li class="solution__modal-li">
                      <img class="solution__segment-logo"
                           :src="'https://static.vadobay.com/airlines-logos/100x50/'+parsed_flights.andata.from.company+'.png'"
                      >
                      <span class="solution__segment-label">
                        {{get_company(parsed_flights.andata.from.company).name | capitalize}}
                        ({{parsed_flights.andata.from.company}})
                      </span>
                    </li>
                    <li class="solution__modal-li">
                      <vb-icon class="solution__segment-icon" :name="'airplane'" :size=16 :color="sass('primary')" />
                      <span class="solution__segment-label">
                        {{parsed_flights.andata.from.tipoVolo}}
                      </span>
                    </li>
                    <li class="solution__modal-li">
                      <vb-icon class="solution__segment-icon" :name="'ticket'" :size=16 :color="sass('primary')" />
                      <div class="solution__segment-label">
                        <span>{{get_classes(parsed_flights.andata.from.classeVolo)}}</span>
                        <span v-if="get_classes(parsed_flights.andata.from.classeVolo) !== parsed_flights.andata.from.classeVolo">
                          ({{parsed_flights.andata.from.classeVolo}})
                        </span>
                      </div>
                      <vb-icon v-if="get_classes(parsed_flights.andata.from.classeVolo) === parsed_flights.andata.from.classeVolo" class="solution__segment-icon solution__segment-icon--info" :name="'info'" :color="sass('gray-500')" :size="13"
                               v-b-popover.hover.top="'Per conoscere la classe ti preghiamo di contattare l\'assistenza in webchat o via email .'"
                      />
                    </li>
                    <li class="solution__modal-li" v-b-modal="'modal-baggage-'+solution.flights[0].aptPart+(solution_index + 1)">
                      <vb-icon class="solution__segment-icon" :name="'baggage'" :size=16 :color="sass('primary')" />
                      <span class="solution__segment-label" v-html="'1 + 1'" />
                      <vb-icon class="solution__segment-icon solution__segment-icon--info" :name="'info'" :color="sass('gray-500')" :size="14" />
                    </li>
                  </ul>
                </vb-modal>

              </div>

              <b-collapse class="solution__collapse" visible accordion="accordion_solution" :id="_uid+solution.flights[0].aptPart+solution_index">
                <div class="solution__rooms" v-for="(hotel, hotel_index) in solution.hotels" v-bind:key="_uid+hotel_index">
                  <div class="solution__rooms-content" v-for="(combination, combination_index) in hotel.roomCombination" v-bind:key="_uid+combination_index">
                    <h3 class="solution__rooms-title">Camere e trattamenti</h3>
                    <b-row>
                      <b-col :class="'solution__rooms-element ' + (room.dispo < 1 ? 'solution__rooms-element--not-available' : '')" lg="12" xl="6" v-for="(room, room_index) in combination.rooms" v-bind:key="_uid+room_index">

                        <vb-card variant="horizontal"
                                 :card="{
                                    image: statics_detail[product.baseProdotto.pid].schedaProdotto.informazioni.known[room.tipoCam] && statics_detail[product.baseProdotto.pid].schedaProdotto.informazioni.known[room.tipoCam].testo_informazione[0].icona_informazione.src !== ''
                                      ? statics_detail[product.baseProdotto.pid].schedaProdotto.informazioni.known[room.tipoCam].testo_informazione[0].icona_informazione.src
                                      : '/assets/images/common/statics/room_placeholder.png',
                                    title: room.descrTipoCamera.charAt(0).toUpperCase() + room.descrTipoCamera.slice(1).toLowerCase() + (room.dispo > 0 ? '<small class=\'text--sm\'>(' + room.dispo + (room.dispo > 1 ? ' disponibili' : ' disponibile') + ')</small>' : ''),
                                    text: statics_detail[product.baseProdotto.pid].schedaProdotto.informazioni.known[room.tipoCam] && statics_detail[product.baseProdotto.pid].schedaProdotto.informazioni.known[room.tipoCam].testo_informazione[0].descrizione,
                                 }"
                                 @click_image="$bvModal.show(room_index + solution.flights[0].aptPart+solution_index)"
                        >
                          <span v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.informazioni.known[room.tipoCam]"
                                class="solution__rooms-readmore"
                                v-b-modal="room_index + solution.flights[0].aptPart+solution_index" v-html="'leggi tutto'"
                          />
                          <vb-modal v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.informazioni.known[room.tipoCam]"
                                    :vb_options="{
                                id: room_index + solution.flights[0].aptPart+solution_index,
                                body_class: 'solution__modal solution__modal--room',
                                centered: true,
                                hide_footer: true,
                                size: 'lg'
                              }"
                          >
                            <img class="solution__modal-image" :src="statics_detail[product.baseProdotto.pid].schedaProdotto.informazioni.known[room.tipoCam].testo_informazione[0].icona_informazione.src" />
                            <h5 class="solution__modal-title" v-html="room.descrTipoCamera.charAt(0).toUpperCase() + room.descrTipoCamera.slice(1).toLowerCase()" />
                            {{statics_detail[product.baseProdotto.pid].schedaProdotto.informazioni.known[room.tipoCam].testo_informazione[0].descrizione}}
                          </vb-modal>

                          <div class="solution__rooms-footer">
                            <div class="solution__rooms-footer_treatment" v-if="room.dispo > 0">
                              <vb-select v-model="supplement[product_index][solution_parsed_index][solution_index][hotel_index][combination_index][room_index]"
                                         :disabled="room.dispo < 1"
                                         :vb_options="{
                                                      options: (room.trattamenti.map(suppl => {
                                                        return {
                                                          value: {
                                                            idGruppoTrattamento: suppl.idGruppoTrattamento,
                                                            totAmount: suppl.totAmount,
                                                            descrizioneOfferta: suppl.descrizioneOfferta
                                                          },
                                                          text: suppl.descrizioneOfferta.charAt(0).toUpperCase() + suppl.descrizioneOfferta.slice(1).toLowerCase()
                                                        }
                                                      })),
                                                      value: supplement[product_index][solution_parsed_index][solution_index][hotel_index][combination_index][room_index]
                                                    }"
                                         @change="selected_room = {}"
                              />
                              <span>{{markup(supplement[product_index][solution_parsed_index][solution_index][hotel_index][combination_index][room_index].totAmount).display_price}}€</span>
                            </div>

                            <span class="solution__rooms-availability" v-else v-html="'Peccato! Le disponibilità sono terminate.'" />

                            <vb-button
                                :use_btn_caption="btn_caption = (selected_room.active_btn_room === solution_parsed_index + '-' + solution_index + '-' + hotel_index + '-' + combination_index + '-' + room_index) ? '<span class=\'text-info\'>Selezionata</span>' : (room.dispo < 1)? 'Esaurita' : 'Scegli'"
                                :vb_options="{
                                text: btn_caption,
                                variant: 'dark',
                                size: 'md',
                                icon_disabled: room.dispo < 1 && 'lock',
                                disabled: room.dispo < 1 || selected_room.active_btn_room === solution_parsed_index + '-' + solution_index + '-' + hotel_index + '-' + combination_index + '-' + room_index
                              }"
                                @click="room_selected(
                                {
                                      product_index: product_index,
                                      solution_parsed_index: solution_parsed_index,
                                      solution_index: solution_index,
                                      hotel_index: hotel_index,
                                      combination_index: combination_index,
                                      room_index: room_index
                                    }
                              )"
                            />
                          </div>
                        </vb-card>

                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-collapse>

            </div>
          </b-tab>
        </vb-tabs>
        <span class="solution__info">
          <disclaimer-flights-info />
        </span>

      </section>

      <!-- RECAP PACCHETTO -->
      <card-recap class="section" v-if="Object.keys(selected_room).length !== 0" :selected_room="selected_room" />

      <div class="d-flex justify-content-center justify-content-lg-end" v-if="Object.keys(selected_room).length === 0">
        <vb-button
            v-if="product.soluzioni_parsed.length > 0"
            :vb_options="{
                text: 'Scegli una camera per continuare',
                variant: 'info',
                size: 'lg',
                disabled: true,
                icon_disabled: 'lock'
          }"/>
      </div>
      <div class="d-flex justify-content-center justify-content-lg-end" v-else>
        <custom-link
            :route_name="'quote'"
            :wrap="'a'"
        >
          <template #content>
            <vb-button
                :vb_options="{
                  text: 'Continua',
                  variant: 'info',
                  size: 'lg',
          }" />
          </template>
        </custom-link>
      </div>

    </div>
  </div>
</template>

<script>
import {custom_event, is_mobile, parse_date, reverse_date} from '@utilities/commons'
import { markup } from '@alpitour/utilities'
import {get_carriers, get_travel_classes} from "@alpitour/resources";
import moment from 'moment';
import { number, smaller } from 'mathjs'
import DisclaimerFlightsInfo from './disclaimer-flights-info.vue';
import Card from '@library/blocks/card'
import CardRecap from '@alpitour/components/blocks/card-recap.vue'

export default {
  name: 'solutions',

  props: {
    statics_detail: Object,
    product_detail: Array,
    supplement: Array,
  },

  components: {
    'vb-card': Card,
    'card-recap': CardRecap,
    'disclaimer-flights-info': DisclaimerFlightsInfo,
  },

  data() {
    return {
      pid: this.$store.state.liveat.pid,
      days: this.$store.state.liveat.days,
      selected_room: {},
    }
  },

  created() {
    this.is_mobile = is_mobile
    this.parse_date = parse_date
    this.reverse_date = reverse_date
    this.markup = markup
    this.get_company = get_carriers
    this.get_classes = get_travel_classes
  },

  mounted() {
  },

  updated() {
    if(Object.keys(this.selected_room).length !== 0) this.scroll_into_view()
  },

  methods: {
    room_selected(ids){

      let trattamento = this.supplement[ids.product_index][ids.solution_parsed_index][ids.solution_index][ids.hotel_index][ids.combination_index][ids.room_index]

      let prodotto = this.product_detail[ids.product_index]
      let soluzione = prodotto['soluzioni_parsed'][ids.solution_parsed_index]['soluzioni'][ids.solution_index]
      let hotel = prodotto['soluzioni_parsed'][ids.solution_parsed_index]['soluzioni'][ids.solution_index]['hotels'][ids.hotel_index]
      let room = prodotto['soluzioni_parsed'][ids.solution_parsed_index]['soluzioni'][ids.solution_index]['hotels'][ids.hotel_index]['roomCombination'][ids.combination_index]['rooms'][ids.room_index]

      let flights = this.parse_flights(soluzione.flights)

      this.selected_room = {

        quote_payload: {

          roomType: room.tipoCam,
          paxA: room.paxA,
          paxC: room.paxC,
          paxI: room.paxI,

          occupancy: room.paxA,
          agesList: hotel.roomCombination[ids.combination_index].occupancy.listEta || prodotto.occupancy.listEta || [],

          boardId: trattamento.idGruppoTrattamento,
          pid: prodotto.baseProdotto.pid, // azie-kpro-kbra-kvac trasformati lato server
          productCardCode: prodotto.editoriale.codSchedaProdotto,
          //nights? // effettivamente presente in documentazione ma abbiamo certificato senza... Gaglio conferma che serve per il "solo land"
          //days: hotel.giorniH,
          days: flights.andata.from.durataVolo,

          // primo volo andata
          departureDate: flights.andata.from.date,
          departureTime: flights.andata.from.time,
          departureAirportCode: flights.andata.from.iata,
          departureProgNumber: flights.andata.from.progVolo,
          departureFlightClass: flights.andata.from.classeVolo,
          departureFlightNumber: flights.andata.from.numVolo,

          // ultimo volo ritorno
          arrivalProgNumber: flights.ritorno.to.progVolo,
          arrivalFlightClass: flights.ritorno.to.classeVolo,
          arrivalFlightNumber: flights.ritorno.to.numVolo,

          // extra
          roomDescription: room.descrTipoCamera,
          roomTrattDescription: trattamento.descrizioneOfferta,
          totAmount: trattamento.totAmount,
        },

        // informazioni per card-recap
        data_andata : flights.andata.from.date,
        data_ritorno: flights.ritorno.to.date,
        aeroporto_partenza_andata: flights.andata.from.label,
        aeroporto_partenza_andata_code: flights.andata.from.iata,
        aeroporto_arrivo_andata: flights.andata.to.label,
        aeroporto_arrivo_andata_code: flights.andata.to.iata,

        active_btn_room: ids.solution_parsed_index + '-' + ids.solution_index + '-' + ids.hotel_index + '-' + ids.combination_index + '-' + ids.room_index,
      }

      this.$emit('selected-room', this.selected_room);
      this.$store.commit('liveat/selected_room', this.selected_room)

      custom_event('room_selected', this.selected_room.quote_payload.roomDescription + ' ' + this.selected_room.quote_payload.roomTrattDescription, this.selected_room.quote_payload.totAmount)
    },

    parse_trattamento(hotel) {
      let minTotAmount = 0,
          listTotAmount = {
            label_trattamento: 'standard',
            a_partire_da: minTotAmount,
            notti: this.days,
          }

      hotel.forEach((hotel) => {
        hotel.roomCombination.forEach((roomCombination) => {
          roomCombination.rooms.forEach((room) => {
            room.trattamenti.forEach((trattamento) => {

              const totAmount = number(trattamento.totAmount);
              const descrizioneOfferta = trattamento.descrizioneOfferta

              if(minTotAmount === 0 || smaller(totAmount, minTotAmount)) minTotAmount = totAmount

              listTotAmount = {
                label_trattamento: descrizioneOfferta,
                a_partire_da: minTotAmount,
                notti: this.days
              }

            });
          });
        });
      });

      return listTotAmount
    },

    parse_flights(solution_flights) {

      let flights = solution_flights.slice().sort((a, b) => {
        return parseInt(a.progressivo) - parseInt(b.progressivo);
      });

      let sorted = {
        andata: [],
        ritorno: []
      }

      let direction = 'andata'
      flights.forEach((f) => {

        if(f.tipoTratta == 'R') direction = 'ritorno' // tipoTratta A andata, * scali, R ritorno

        sorted[direction].push(f)

      })


      let parsed = {}
      Object.keys(sorted).forEach((direction) => {

        let flights = sorted[direction]

        flights.forEach(flight => {
          flight.dataLand = moment(flight.orarioArr, "HH:mm:ss").isBefore(moment(flight.orarioPart, "HH:mm:ss"))
              ? moment(flight.dataVolo).add(1, 'days').format('YYYY-MM-DD')
              : flight.dataVolo;

          flight.signaln = flight.dataLand !== flight.dataVolo ? 1 : flight.signaln;
        });

        parsed[direction] = {

          from: {
            iata: flights[0].aptPart,
            label: flights[0].descrAptPart,
            date: flights[0].dataVolo,
            time: flights[0].orarioPart,
            progVolo: flights[0].progVolo,
            classeVolo: flights[0].classeVolo,
            numVolo: flights[0].numVolo,
            airportDescription: flights[0].descrAptPart,
            company: flights[0].siglaVolo,
            tipoVolo: flights[0].tipoVolo,
            durataVolo: flights[0].durataVolo,
          },

          to: {
            iata: flights[flights.length-1].aptArr,
            label: flights[flights.length-1].descrAptArr,
            date: flights[flights.length-1].dataLand,
            time: flights[flights.length-1].orarioArr,
            progVolo: flights[flights.length-1].progVolo,
            classeVolo: flights[flights.length-1].classeVolo,
            numVolo: flights[flights.length-1].numVolo,
            airportDescription: flights[flights.length-1].descrAptArr,
            company: flights[flights.length-1].siglaVolo,
            tipoVolo: flights[flights.length-1].tipoVolo,
            durataVolo: flights[flights.length-1].durataVolo,
          },

          segments: flights,
          scali: flights.length-1,

          signaln: this.is_date_after(flights[0].dataVolo, flights[flights.length-1].dataLand),

        }

      })

      return parsed

    },

    scroll_into_view() {
      this.$refs['solution'].scrollIntoView({ behavior: 'smooth', block: "end" });
    },

    is_date_after(from_time, to_time) {

      const formatted_from = moment(from_time, 'YYYY-MM-DD')
      const formatted_to = moment(to_time, 'YYYY-MM-DD')

      return formatted_to.diff(formatted_from, 'days')
    }
  }
}
</script>

<style lang="scss" scoped>

  .solution {
    @extend %section;

    &__title {
      margin-bottom: 1.5rem;
    }

    &__content {
      margin-top: 1.25rem;
      padding-top: 1.25rem;
      border-top: 1px solid $gray-200;

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }
    }

    &__recap {
      margin-bottom: $grid-gutter-width/3;
      position: relative;

      @include media-breakpoint-up(lg) {
        display: flex;
        margin-bottom: $grid-gutter-width/2;
      }

      &-title {
        font-size: $font-size-lg;
        color: $secondary;
        font-weight: $vb-font-weight-semibold;
        margin-right: 1.25rem;
      }

      &-icon {
        margin-right: $grid-gutter-width/6;
        display: none;

        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
      }

      &-wrap {
        display: flex;
      }

      &-element {
        display: flex;
        align-items: center;
        color: $primary;
        margin-right: $grid-gutter-width/6;

        &:first-child {
          margin-right: 0;

          @include media-breakpoint-up(lg) {
            margin-right: $grid-gutter-width/6;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &-iata {
        display: none;

        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
      }

      &-departure, &-arrival {
        display: none;

        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
      }

      &-departure {
        &:after {
          content: '-';
          display: inline-block;
          margin-right: $grid-gutter-width/10;
        }
      }
      &-nights {
        position: absolute;
        top: 0;
        right: 0;
        font-size: $font-size-sm;

        @include media-breakpoint-up(lg) {
          position: static;
          display: flex;
          align-items: center;
          font-size: $font-size-base;
        }

        &:before {
          @include media-breakpoint-up(lg) {
            content: "(";
          }
        }
        &:after {
          @include media-breakpoint-up(lg) {
            content: ")";
          }
        }

        @include media-breakpoint-up(lg) {
          margin-left: $grid-gutter-width/6;
        }
      }

    }

    &__segment {
      $this: &;
      align-items: center;

      @include media-breakpoint-up(xl) {
        display: flex;
      }

      &-element {
        display: flex;
        align-items: center;

        @include media-breakpoint-up(xl) {
          border-left: 1px solid $gray-500;
          padding-left: $grid-gutter-width/2;
          margin-left: $grid-gutter-width/2;
        }

        &:first-child, &:last-child {
          border-left: 0;
          padding-left: 0;
          margin-left: 0;
        }

        &:last-child {
          margin-left: $grid-gutter-width;
        }

        &--departure {
          margin-bottom: $grid-gutter-width/6;

          @include media-breakpoint-up(xl) {
            margin-bottom: 0;
          }
        }

        &--carriers, &--typeflight, &--class, &--baggage {
          display: none;

          @include media-breakpoint-up(xl) {
            display: flex;
          }
        }

        &--cta {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 0 !important;
          margin-top: $grid-gutter-width/2;
          padding-top: $grid-gutter-width/6;
          border-top: 1px solid $gray-200;
        }
      }

      &-icon {
        margin-right: $grid-gutter-width/6;

        &--departure, &--return {
          display: none;

          @include media-breakpoint-up(xl) {
            display: inline-block;
          }
        }

        &--return {
          transform: scaleX(-1);
        }

        &--info {
          margin-left: $grid-gutter-width/6;
          margin-top: 2px;
        }

        // mobile version link Dettagli
        &--arrow {
          margin-top: $grid-gutter-width/6;

          @at-root #{$this}-link.not-collapsed & {
            transform: rotate(-180deg);
          }
        }


      }

      &-title {
        display: block;

        @include media-breakpoint-up(xl) {
          display: none;
        }
      }

      &-label {
        font-weight: $vb-font-weight-semibold;
      }

      &-time {
        margin-left: $grid-gutter-width/3;
      }

      &-plus {
        margin-left: $grid-gutter-width/10;
      }

      &-type {
        margin-left: $grid-gutter-width/6;

        .text-info {
          text-decoration: underline;
        }
      }

      &-logo {
        margin-right: $grid-gutter-width/6;
        height: 1.45rem;
      }

      &-link {
        color: $info;

        &--detail {
          display: flex;
          align-items: center;

          &.not-collapsed {
            text-decoration: none;
          }
        }
      }

      &-btn {

        &--details {
          @at-root #{$this}-element.not-collapsed & {
            color: $info;
            border-color: transparent;
            background-color: $vb-body-bg;
            box-shadow: inset 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15), 1px 1px 5px #fff, inset -0.625rem -0.625rem 1.25rem rgba(255, 255, 255, 0.5) !important;
          }
        }
      }
    }

    &__rooms {
      margin-top: $grid-gutter-width;

      &-title {
        font-size: $font-size-lg;
        margin-bottom: 1.563rem;
      }

      &-element {
        margin-bottom: $grid-gutter-width/2;
      }

      &-readmore {
        position: absolute;
        margin-top: -1.125rem;
        right: 1.9rem;
        background: $white;
        padding-left: $grid-gutter-width/3;
        color: $info;
        text-decoration: underline;
        cursor: pointer;
        font-size: $font-size-sm;
      }

      &-footer {
        margin-top: $grid-gutter-width/2;
        padding: $grid-gutter-width/2 0 0;
        border-top: 1px solid $gray-100;
        background-color: transparent;

        @include media-breakpoint-up(xl) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &_treatment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-basis: 60%;

          .vb-select {
            font-size: $font-size-sm;
            color: $dark;
          }

          span {
            margin-left: $grid-gutter-width/2;
            font-size: $font-size-lg;
            color: $dark;
            font-weight: $vb-font-weight-semibold;
          }
        }

        .vb-btn {
          display: block;
          width: 100%;
          margin-top: $grid-gutter-width/3;

          @include media-breakpoint-up(xl) {
            margin-top: 0;
            flex-basis: 30%;
          }
        }
      }

      &-availability {
        display: block;
        font-size: .85rem;
        text-align: center;
        color: $secondary;

        @include media-breakpoint-up(xl) {
          text-align: left;
        }
      }
    }

    &__not-found {
      font-size: $font-size-lg;
      margin-bottom: $grid-gutter-width/2;

      strong {
        font-weight: $vb-font-weight-semibold;
      }

      img {
        margin-bottom: $grid-gutter-width/2;
      }
    }

    &__info {
      font-size: $font-size-sm;
      color: $gray-600;
      text-align: right;
      margin-top: $grid-gutter-width/2;
      display: block;
    }

    &__modal {
      $this: &;

      &-element {
        border-bottom: 1px solid $gray-200;
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
      }

      &-wrap {

        @include media-breakpoint-up(xl) {
          display: flex;
          align-items: center;
        }
      }

      &-title {
        font-size: $font-size-lg;
        margin-bottom: $grid-gutter-width/2;
        font-weight: $vb-font-weight-semibold;
        display: flex;
        align-items: center;

        @at-root #{$this}--room & {
          font-size: 1.375rem;
        }

        &_icon {
          margin-right: $grid-gutter-width/6;
        }
      }

      &-image {

        @at-root #{$this}--room & {
          border-radius: $border-radius;
          margin-bottom: 1.25rem;
        }
      }

      &--segment {
        padding-bottom: 1.25rem;
        margin-top: -2.5rem;
      }

      &-info {
        @extend .solution__info;
      }

      &-ul {
        padding-left: 0;
      }
      &-li {
        list-style: none;
        display: flex;
        align-items: center;
        margin-top: $grid-gutter-width/3;
        border-top: 1px solid $gray-200;
        padding-top: $grid-gutter-width/3;

        &:first-child {
          margin-top: 0;
          border-top: 0;
        }
      }
    }
  }

  .segment {
    $this: &;

    @include media-breakpoint-up(xl) {
      display: flex;
      align-items: center;
    }

    &__element {
      margin-bottom: $grid-gutter-width/6;

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
    }

    &__plus {
      margin-left: $grid-gutter-width/10;
    }

    &__flight {

      @include media-breakpoint-up(xl) {
        margin-left: $grid-gutter-width/3;
        margin-right: $grid-gutter-width/3;
      }
    }

    &__logo {
      height: 2rem;
    }

    &__deco {
      display: none;

      @include media-breakpoint-up(xl) {
        display: block;
      }

      &:before, &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
      }

      &:before {
        $size: 12px;
        width: $size;
        height: $size;
        border-radius: 50%;
        border: 1px solid $gray-500;
      }

      @at-root #{$this}:nth-child(odd) & {
        margin-left: 1.25rem;

        &:before {
          margin-right: $grid-gutter-width/6;
        }

        &:after {
          width: 25px;
          height: 1px;
          background-color: $gray-500;
        }
      }

      @at-root #{$this}:nth-child(even) & {
        order: -1;
        margin-left: $grid-gutter-width/6;
        margin-right: 1.25rem;
      }
    }
  }

  ::v-deep {

    .vb-card {

      &__image {
        overflow: hidden;

        &[style*="room_placeholder"] {
          &:hover {
            cursor: default;
          }

          &:after {
            display: none !important;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: rgba(255,255,255, .5);
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci16b29tLWluIiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxjaXJjbGUgY3g9IjExIiBjeT0iMTEiIHI9IjgiLz48bGluZSB4MT0iMjEiIHgyPSIxNi42NSIgeTE9IjIxIiB5Mj0iMTYuNjUiLz48bGluZSB4MT0iMTEiIHgyPSIxMSIgeTE9IjgiIHkyPSIxNCIvPjxsaW5lIHgxPSI4IiB4Mj0iMTQiIHkxPSIxMSIgeTI9IjExIi8+PC9zdmc+');
          background-size: 60%;
          background-repeat: no-repeat;
          background-position: center center;
          width: 40px;
          height: 40px;
          border-radius: 200px;

          @include media-breakpoint-up(lg) {
            display: none;
            top: auto;
            right: 30px;
            bottom: 10px;
          }
        }

        &:hover {
          cursor: pointer;

          &:after {
            display: block;
          }
        }
      }

      &__title {
        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &__text {
        font-size: $font-size-sm;
        height: 60px;
        overflow: hidden;
      }

      &__wrap {
        border: 1px solid $gray-200;
      }
    }
  }

</style>